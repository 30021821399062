//
// Colors
// --------------------------------------------------
$white:             #ffffff;
$gray-100:          #f2f2f2;
$gray-200:          #e9e9e9;
$gray-300:          #dedede;
$gray-400:          #cecece;
$gray-500:          #ababab;
$gray-600:          #6c6c6c;
$gray-700:          #494949;
$gray-800:          #313131;
$gray-900:          #212121;
$black:             #000000;

$indigo:            #251657;
$bright-green: 		#3beccd;

$primary:           $indigo;
$success:           #5cb85c;
$info:              #5bc0de;
$warning:           #f0ad4e;
$danger:            #d9534f;


$link-color: $primary;
$link-hover-color: darken($link-color, 15%);
$link-border-color: $bright-green;
$link-border-hover-color: darken($bright-green, 15%);
$link-alternate-color: #ffffff;
$link-alternate-hover-color: darken($link-alternate-color, 15%);
$table-bg:          $white;

$yiq-contrasted-threshold: 160;

//
// Code Colors
// --------------------------------------------------
$code-color:        #ee0028;
$code-color-file:   hsl(328, 100%, 50%);
$code-color-html:   hsl(275, 100%, 40%);
$code-color-js:     hsl(128, 100%, 20%);
$code-color-method: hsl(210, 100%, 35%);
$code-color-php:    hsl(210, 100%, 35%);
$code-color-py:     hsl(210, 100%, 50%);
$code-color-ts:     hsl(12, 100%, 20%);
$code-color-yaml:   hsl(170, 80%, 30%);

//
// Base 16 Color Scheme
// --------------------------------------------------
$base16-base00:     #212121;
$base16-base01:     #2e3c43;
$base16-base02:     #314549;
$base16-base03:     #546e7a;
$base16-base04:     #b2ccd6;
$base16-base05:     #eeffff;
$base16-base06:     #eeffff;
$base16-base07:     #ffffff;
$base16-base08:     #f07178;
$base16-base09:     #f78c6c;
$base16-base0A:     #ffcb6b;
$base16-base0B:     #c3e88d;
$base16-base0C:     #89ddff;
$base16-base0D:     #82aaff;
$base16-base0E:     #c792ea;
$base16-base0F:     #ff5370;

//
// Fonts
// --------------------------------------------------
$font-family-sans-serif: "Apercu Pro", "Segoe UI", helvetica, arial, sans-serif;
$font-family-monospace: "Source Code Pro", monospace, courier, times, serif;
$font-weight-bold: 600;

$h1-font-size: 2em;
$h2-font-size: 1.75em;
$h3-font-size: 1.5em;
$h4-font-size: 1.25em;
$h5-font-size: 1em;
$h6-font-size: .85em;

$headings-font-weight: $font-weight-bold;
$headings-margin-bottom: .5em;

//
// Sizes
// --------------------------------------------------
$size-base: 16px;
$size-1: $size-base * 0.25;
$size-2: $size-base * 0.5;
$size-3: $size-base * 0.75;
$size-4: $size-base;
$size-5: $size-base * 1.5;
$size-6: $size-base * 2;
$size-7: $size-base * 3;
$size-8: $size-base * 4;
$size-9: $size-base * 6;
$size-10: $size-base * 8;
$size-11: $size-base * 12;
$size-12: $size-base * 16;
$size-13: $size-base * 24;
$size-14: $size-base * 32;
$size-15: $size-base * 40;
$size-16: $size-base * 48;

//
// Components
// --------------------------------------------------
$box-shadow-sm: 0 2px 4px 0 rgba(0, 0, 0, .2);
$breadcrumb-divider: quote(">");

//
// Cards
// --------------------------------------------------
$card-spacer-y: 1.25rem;
$card-spacer-x: 1.25rem;

//
// Grid
// --------------------------------------------------
$grid-gutter-width: 40px;

