@font-face {
    font-family: "Apercu Pro";
    font-display: swap;
    src: url("../fonts/apercu/apercu-light-pro.woff2");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Apercu Pro";
    font-display: swap;
    src: url("../fonts/apercu/apercu-regular-pro.woff2");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Apercu Pro";
    font-display: swap;
    src: url("../fonts/apercu/apercu-bold-pro.woff2");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Apercu Pro";
    font-display: swap;
    src: url("../fonts/apercu/apercu-black-pro.woff2");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Source Code Pro";
    font-display: swap;
    src: url("../fonts/source-code-pro/SourceCodePro-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Source Code Pro";
    font-display: swap;
    src: url("../fonts/source-code-pro/SourceCodePro-Italic.ttf");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: "Source Code Pro";
    font-display: swap;
    src: url("../fonts/source-code-pro/SourceCodePro-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Source Code Pro";
    font-display: swap;
    src: url("../fonts/source-code-pro/SourceCodePro-BoldItalic.ttf");
    font-weight: 700;
    font-style: italic;
}
